
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_JOBS = '/jobs';
const ROOTS_SETTINGS = '/settings';
const ROOTS_PAY = '/pay';
const ROOTS_STANDUP = '/standup';
const ROOTS_MEETING = '/meeting';
const ROOTS_REGISTER = '/register';
const ROOTS_AGENCY = '/agency';
const ROOTS_TODO = '/todo';
const ROOTS_CONTRACT = '/contracts';
const ROOTS_RENEW = '/renew';
const ROOTS_EVENT = '/event';
const ROOTS_TIME = '/time';
const ROOTS_CREWS = '/crews';
const ROOTS_SALARY = '/salary';
const ROOTS_REVIEW = '/review';
const ROOTS_FLIERS = '/fliers';
const ROOTS_SCOUT = '/scout';
const ROOTS_TECHLEAD = '/techlead';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  personalInfo: path(ROOTS_AUTH, '/personal-info'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
};

export const PATH_JOBS = {
  root: ROOTS_JOBS,
  instant: path(ROOTS_JOBS, '/instant'),
};

export const PATH_PAY = {
  root: ROOTS_PAY,
};

export const PATH_SALARY = {
  root: ROOTS_SALARY,
  approve: path(ROOTS_SALARY, '/approve'),
  check: path(ROOTS_SALARY, '/check'),
  checkDetail: path(ROOTS_SALARY, '/check/detail'),
  complete: path(ROOTS_SALARY, '/complete'),
  completeDetail: path(ROOTS_SALARY, '/complete/detail'),
}

export const PATH_STANDUP = {
  root: ROOTS_STANDUP,
  detail: path(ROOTS_STANDUP, '/detail'),
};

export const PATH_MEETING = {
  root: ROOTS_MEETING,
};

export const PATH_REGISTER = {
  root: ROOTS_REGISTER,
  position: path(ROOTS_REGISTER, '/position'),
  company: path(ROOTS_REGISTER, '/company'),
  meeting: path(ROOTS_REGISTER, '/meeting'),
  project: path(ROOTS_REGISTER, '/project'),
};

export const PATH_AGENCY = {
  root: ROOTS_AGENCY,
};

export const PATH_TODO = {
  root: ROOTS_TODO,
  detail: path(ROOTS_TODO, '/detail'),
};

export const PATH_CONTRACT = {
  root: ROOTS_CONTRACT,
  terminate: path(ROOTS_CONTRACT, '/terminate'),
  renew: path(ROOTS_CONTRACT, '/renew'),
  deposit: path(ROOTS_CONTRACT, '/deposit'),
};

export const PATH_EVENT = {
  invitation: path(ROOTS_EVENT, '/invitation'),
};

export const PATH_CREWS = {
  root: ROOTS_CREWS,
};

export const PATH_RENEW = {
  root: ROOTS_RENEW,
};

export const PATH_TIME = {
  root: ROOTS_TIME,
};

export const PATH_REVIEW = {
  root: ROOTS_REVIEW,
  detail: path(ROOTS_REVIEW, '/detail'),
};

export const PATH_FLIERS = {
  root: ROOTS_FLIERS,
  proposal: path(ROOTS_FLIERS, '/proposal'),
  profile: path(ROOTS_FLIERS, '/profile'),
  follower: path(ROOTS_FLIERS, '/follower'),
};

export const PATH_SCOUT = {
  root: ROOTS_SCOUT,
  manage: path(ROOTS_SCOUT, '/manage'),
  profile: path(ROOTS_SCOUT, '/profile'),
};

export const PATH_TECHLEAD = {
  root: ROOTS_TECHLEAD,
};
